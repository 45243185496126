import { del, get, patch, post } from "./api";
import { projects } from "../stores/store";
import { get as getFromStore } from "svelte/store";
import { getSetting } from "./settings";

export const getProjects = async () => {
  if (true || !getFromStore(projects)) {
    const url = `/projects`;
    const response = await get(url);
    projects.set(response.data);
  }
  return projects;
};

export const getProject = async (id) => {
  if (false && getFromStore(projects)) {
    const match = getFromStore(projects).filter((p) => {
      return p._id === id;
    });
    if (match.length) {
      return match[0];
    }
  }
  const url = `/projects/${id}`;
  const data = await get(url);
  return data;
};

export const getQuestions = async (id) => {
  const project = await getProject(id);
  try {
    return project.questionSet.questions;
  } catch (error) {
    return [];
  }
};

export const getNameQuestionIndexes = async (id) => {
  const questions = await getQuestions(id);

  return questions
    .filter((q, index) => {
      q.id = "question--" + index;
      return q.kind == "Text";

      return false;
    })
    .map((q) => q.id);
};

export const getParticipants = async (id) => {
  const url = `/projects/${id}/participants?$limit=10000`;
  const data = await get(url);
  return data.data;
};

export const addParticipant = async (id, email) => {
  const url = `/projects/${id}/participants`;
  const data = await post(url, { email });
  return data;
};

export const deleteParticipant = async (id, email) => {
  email = encodeURIComponent(email);
  const url = `/projects/${id}/participants/${email}`;
  const result = await del(url);
  return result;
};

export const getFacilitators = async (id) => {
  const url = `/projects/${id}/facilitators?$limit=10000`;
  const data = await get(url);
  return data.data;
};

export const addFacilitator = async (id, email) => {
  const url = `/projects/${id}/facilitators`;
  const data = await post(url, { email });
  return data;
};

export const deleteFacilitator = async (id, email) => {
  email = encodeURIComponent(email);
  const url = `/projects/${id}/facilitators/${email}`;
  const result = await del(url);
  return result;
};

export const createProject = async (project) => {
  const url = `/projects`;

  let defaultQuestions = await getSetting("defaultQuestions");
  let questions;
  if (defaultQuestions) {
    try {
      defaultQuestions = JSON.parse(defaultQuestions.content);
      if (defaultQuestions.labelProject) {
        defaultQuestions.labelProject = project.name;
      }
      questions = defaultQuestions;
    } catch (e) {
      // let it fail silently
    }
  }
  if (!questions) {
    questions = {
      labelOrganisation: "Mapping Social Capital",
      labelProject: project.name,
      questions: [],
    };
  }

  project.questionSet = questions;
  const data = await post(url, project);
  return data;
};

export const updateProject = async (project) => {
  const url = `/projects/${project._id}`;
  const data = await patch(url, project);
  if (!data.errors && getFromStore(projects)) {
    // update the project store to reflect the updated project
    const projectSlice: any[] = getFromStore(projects).map(
      (p: Record<string, any>) => {
        return p._id === data._id ? data : p;
      }
    );
    projects.set(projectSlice);
  }

  return data;
};

export const deleteProject = async (project) => {
  const url = `/projects/${project._id}`;
  const result = await del(url);
  projects.set(getFromStore(projects).filter((p) => p._id != project._id));
  return result;
};

export const getCSV = async (project) => {
    const url = `/projects/${project._id}/export`;
    const result = await get(url);
    return result;
}